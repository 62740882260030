import React from "react";
import plugins from '../../content/pluginsInfo'
import Layout from "../components/layout"

export default class Images extends React.Component{
    componentWillMount() {
        // window.scrollTo(0,0)
    }

    render() {
        const plugin=plugins[5]
        const styles=plugin.styleImgs.map((style,index)=><li className="animate-up" key={index}>
            <div > <img className="border" src={style} alt="示意图" /></div>
        </li>)
        const demos=plugin.useInDemos.map((demo,index)=>
          <li className="animate-up" key={index}>
              <a href={demo.path}> <img className="border"  src={demo.poster}  alt="示意图" />
                  <div>{demo.name}</div></a>
          </li>
        )
        return(
          <Layout>
            <div>
                <section className="section light-blue cutoff">
                    <div className="pagewidth">
                        <h1>照片展示栏</h1>
                        <h2>赛事宣传图，比赛现场图，选手比赛照片，赛后颁奖图等等，快速浏览各种赛事图片。</h2>
                    </div>
                </section>


                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>多种样式</h1>
                        <ul className="grid3 slide-up animated">
                            {styles}
                        </ul>
                    </div>
                </section>

                <section className="section light-gray cutoff">
                    <div className="pagewidth">
                        <h1>适用模板</h1>
                        <ul className="grid3 slide-up animated">
                            {demos}

                        </ul>
                    </div>
                </section>
            </div>
          </Layout>
        )
    }
}